import { useSelector } from 'react-redux'
import { StyledFooter, Column, CustomLink, CustomText, Flex } from './Footer.Styled'
import { Container } from '@think-internet/ui-components'
import props from '../../../redux/props'

const Footer = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])

  return (
    <StyledFooter>
      <Container>
        <Flex>
          <Column>
            <CustomLink newTab href="https://www.think-internet.de/impressum" text={translation.imprint.headline} />
          </Column>
          <Column>
            <CustomLink newTab href="https://www.think-internet.de/datenschutz" text={translation.dataProtection.headline} />
          </Column>
          <Column>
            <CustomText text={`Think Internet Operations GmbH©${new Date().getFullYear()}`} />
          </Column>
        </Flex>
      </Container>
    </StyledFooter>
  )
}

export default Footer
