import { Container } from '@think-internet/ui-components'
import Layout from '../../Layout/Layout'
import { StyledOverview } from './Overview.Styled'
import PersonalData from '../PersonalData/PersonalData'

const Overview = () => {
  return (
    <Layout>
      <Container>
        <StyledOverview>
          <PersonalData />
        </StyledOverview>
      </Container>
    </Layout>
  )
}

export default Overview
