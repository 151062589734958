import styled from "styled-components";

export const StyledArticle = styled.div`
  width: 100%;
`;

export const Name = styled.a`
  color: ${({ theme }) => theme.color.font.primary};
  text-decoration: none;
`;
