import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import GlobalStyle from "./Global.Styled";
import { ThemeProvider } from "styled-components";
import theme from "./theme";
import Login from "./components/Login/Login";
import Signup from "./components/Signup/Signup";
import Activate from "./components/Signup/Activate/Activate.js";
import Dashboard from "./components/Dashboard/Dashboard";
import Profile from "./components/Profile/Profile.js";
import "./global.scss";

function App() {
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <GlobalStyle />
        <Router>
          <Routes>
            <Route index element={<Login />} />
            <Route exact path={"signup"} element={<Signup />} />
            <Route exact path={"activate/:uuid"} element={<Activate />} />
            <Route path={"dashboard/*"} element={<Dashboard />} />
            <Route path={"profile/*"} element={<Profile />} />
            <Route path={"*"} element={<Navigate replace to="/" />} />
          </Routes>
        </Router>
        <ToastContainer />
      </ThemeProvider>
    </div>
  );
}

export default App;
