import styled from 'styled-components'

export const StyledArticleView = styled.div``

export const Elements = styled.div`
  padding: 50px 0;
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: right;
`

export const UploadLabel = styled.div`
  width: 100%;
  padding: 40px;
  background-color: ${({ theme }) => theme.color.background.fourth};
  display: flex;
  align-items: center;
  justify-content: center;
`

export const Controls = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`
