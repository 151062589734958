import styled from 'styled-components'
import { Link } from '@think-internet/ui-components'

export const StyledFooter = styled.div`
  display: flex;
  width: 100%;
  align-items: center;
  border-top: thin solid ${({ theme }) => theme.color.lightblack};
`

export const Column = styled.div`
  flex: 1 1 0px;
`

export const CustomLink = styled(Link)`
  color: ${({ theme }) => theme.color.black};
  font-size: ${({ theme }) => theme.font.size.md};
`

export const CustomText = styled.div`
  font-size: ${({ theme }) => theme.font.size.sm};
  color: ${({ theme }) => theme.color.lightblack};
`

export const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 25px;
  align-items: center;
`
