import { Container } from "@think-internet/ui-components";
import Layout from "../../Layout/Layout";
import { StyledOverview } from "./Overview.Styled";
import Create from "./Blog/Create/Create";
import List from "./Blog/List/List";

const Overivew = () => {
  return (
    <Layout>
      <StyledOverview>
        <Container>
          <Create />
          <List />
        </Container>
      </StyledOverview>
    </Layout>
  );
};

export default Overivew;
