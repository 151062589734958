//
// ONLY EDIT THIS FILE IN /frontend/src/
//

const translation = {
  logoText: 'Blog Provider',

  dashboard: {
    nav: {
      dashboard: 'Dashboard',
      profile: 'Profil',
    },
    logoutCTA: 'Ausloggen',
    loginCTA: 'Einloggen',
    signupCTA: 'Registrieren',
  },

  copy: {
    cta: 'Kopieren',
    success: 'Kopiert',
  },

  imprint: {
    headline: 'Impressum',
  },

  dataProtection: {
    headline: 'Datenschutz',
  },

  blog: {
    create: {
      toggle: 'Blog anlegen',
      submit: 'anlegen',
      name: 'Name',
      domains: 'Domain(-s) (Beispiel: https://think-internet.de, https://www.think-internet.de)',
    },
    list: {
      headline: 'Blogs',
    },
    article: {
      headline: 'Überschrift',
      publicSince: 'Veröffentlicht am',
      asset: {
        dragAndDropLabel: 'Bild/Video',
        dragAndDropLabelHint: 'max. 20 MB',
        maxFileSizeExceededToast: 'Bild/Video ist größer als 20 MB',
      },
      text: 'Text',
      remove: 'Löschen',
      submit: 'speichern',
      error: {
        fetch: 'Artikel konnte nicht geladen werden',
        submit: 'Artikel konnte nicht gespeichert werden.',
        remove: 'Artikel konnte nicht gelöscht werden.',
      },
      success: {
        submit: 'Artikel gespeichert',
      },
      create: {
        toggle: 'Artikel anlegen',
        submit: 'anlegen',
        headline: 'Überschrift',
        error: {
          submit: 'Artikel konnte nicht angelegt werden.',
        },
      },
    },
  },

  profile: {
    personalData: {
      headline: 'Persönliche Daten',
      error: {
        submit: 'Speichern fehlgeschlagen',
      },
      success: {
        submit: 'Daten gespeichert',
      },
      upload: {
        title: 'Profilbild wählen',
        maxFileSizeExceededToast: 'Maximale Dateigröße von 5 MB überschritten.',
      },
    },
  },

  generic: {
    salutation: 'Anrede',
    firstname: 'Vorname',
    lastname: 'Nachname',
    street: 'Straße',
    postcode: 'Postleitzahl',
    city: 'Stadt',
    phone: 'Telefon',
    fax: 'Fax',
    mail: 'E-Mail',
    password: 'Passwort',
    save: 'Speichern',
  },

  login: {
    headline: 'Login',
    cta: 'Einloggen',
    error: 'E-Mail Adresse oder Passwort falsch.',
  },

  signup: {
    headline: 'Registrieren',
    cta: 'Einloggen',
    error: 'Diese E-Mail Adresse wird bereits verwendet.',
    success: 'Wir haben dir eine E-Mail zur Bestätigung geschickt.',
    activate: {
      loginCTA: 'Zum Login',
      success: 'Dein Account wurde erfolgreich aktiviert, du kannst dich nun einloggen.',
      error: 'Dein Account konnte nicht aktiviert werden.',
    },
  },

  UI: {
    dropdown: {
      emptySelection: 'Bitte wählen',
    },
  },
}

module.exports = {
  translation,
  langKey: 'DE',
}
