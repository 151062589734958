import { StyledCreate, ToggleWrapper, Elements } from "./Create.Styled";
import useCRUD from "../../../../interface/useCRUD";
import props from "../../../../redux/props";
import { Button, Form, Input } from "@think-internet/ui-components";
import { useNavigate } from "react-router-dom";
const { useState } = require("react");
const { useSelector } = require("react-redux");

const Create = ({ blogUUID }) => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const [open, setOpen] = useState(false);
  const [data, setData] = useState({});
  const { create } = useCRUD(props.BLOG_ARTICLE);
  const navigate = useNavigate();

  const submit = async () => {
    const result = await create(
      { ...data, blogUUID },
      translation.blog.article.create.error.submit
    );
    if (result) {
      navigate(`/dashboard/blog/${blogUUID}/${result.uuid}`);
    }
  };
  const setProp = (prop) => (value) => setData({ ...data, [prop]: value });

  return (
    <StyledCreate>
      <ToggleWrapper>
        <Button
          text={translation.blog.article.create.toggle}
          onClick={() => setOpen(!open)}
        />
      </ToggleWrapper>
      {open && (
        <Form onSubmit={submit}>
          <Elements>
            <Input
              value={data.headline}
              onChange={setProp("headline")}
              placeholder={translation.blog.article.create.headline}
            />
            <Button
              text={translation.blog.article.create.submit}
              type="submit"
            />
          </Elements>
        </Form>
      )}
    </StyledCreate>
  );
};

export default Create;
