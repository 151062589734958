import { Route, Routes } from 'react-router-dom'
import Layout from '../Layout/Layout'
import Overview from './Overview/Overview.js'

const Profile = () => {
  return (
    <Layout>
      <Routes>
        <Route path={'*'} element={<Overview />} />
      </Routes>
    </Layout>
  )
}

export default Profile
