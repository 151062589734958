import { StyledBlog, Name } from './Blog.Styled'

const Blog = ({ data }) => {
  return (
    <StyledBlog>
      <Name href={`/dashboard/blog/${data.uuid}`}>
        {data.name} ({data.domains.join(', ')})
      </Name>
    </StyledBlog>
  )
}

export default Blog
