import styled from "styled-components";

export const StyledPersonalData = styled.div``;

export const Split = styled.div`
  display: flex;
  margin: 15px 0px;
  gap: 25px;
  align-items: center;
`;

export const InputWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  width: 100%;
`;

export const InputSplit = styled.div`
  display: flex;
  gap: 15px;
  width: 100%;
`;

export const Headline = styled.div`
  font-family: "bold";
`;

export const SubmitWrapper = styled.div`
  display: flex;
  justify-content: right;
`;
