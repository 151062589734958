import { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom'
import Layout from '../../Layout/Layout'
import { StyledActivate, Message } from './Activate.Styled'
import useFunctional from '../../../interface/useFunctional'
import routes from '../../../redux/routes'
import { useSelector } from 'react-redux'
import props from '../../../redux/props'
import { Button } from '@think-internet/ui-components'

const Activate = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const { uuid } = useParams()
  const call = useFunctional(routes.activate_account)
  const [status, setStatus] = useState(null)

  useEffect(() => {
    const activate = async () => {
      const result = await call({ uuid })
      setStatus(!!result)
    }

    if (uuid) {
      activate()
    }
  }, [uuid, call])

  return (
    <Layout authRequired={false}>
      <StyledActivate>
        {status === true && (
          <>
            <Message>{translation.signup.activate.success}</Message>
            <Button href="/login" text={translation.signup.activate.loginCTA} />
          </>
        )}
        {status === false && <Message>{translation.signup.activate.error}</Message>}
      </StyledActivate>
    </Layout>
  )
}

export default Activate
