import styled from 'styled-components'
import { Link } from '@think-internet/ui-components'

export const StyledHeader = styled.div`
  position: absolute;
  width: 100vw;
  height: ${({ theme }) => theme.headerHeight};
  z-index: 9999;
  top: 0;
  background-color: ${({ theme }) => theme.color.background.secondary};
`

export const ItemWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
`

export const Item = styled.div`
  flex: 1 1 0px;
  margin: 0px 15px;
  color: ${({ theme }) => theme.color.font.primary};
  display: flex;
  align-items: center;
  &:last-child {
    text-align: right;
  }
`

export const Toggle = styled.div`
  cursor: pointer;
  margin-right: 15px;
  color: ${({ theme }) => theme.color.font.secondary};
  .icon {
    font-size: 30px;
  }
`

export const Navigation = styled.div`
  padding: 100px 0px;
  background-color: ${({ theme }) => theme.color.background.primary};
  position: absolute;
  width: 100vw;
  height: calc(100vh - ${({ theme }) => theme.headerHeight});
  margin-top: ${({ theme }) => theme.headerHeight};
  top: 0px;
  left: 0px;
  display: flex;
  gap: 15px;
  flex-wrap: wrap;
  align-content: flex-start;
`

export const CustomLink = styled(Link)`
  margin: 10px 0px;
  display: block;
  color: ${({ theme }) => theme.color.font.thirdary};
  ${({ onClick }) => !onClick && 'cursor: default'};
  cursor: pointer;
  ${({ bold }) =>
    bold
      ? `
    font-family: 'bold';
    margin-top: 20px;
  `
      : 'margin-left: 20px;'};
  &:hover {
    ${({ onClick }) => onClick && 'text-decoration: underline;'};
  }
`

export const Logo = styled.img`
  cursor: pointer;
  height: ${({ theme }) => `calc(${theme.headerHeight} - 20px)`};
`
