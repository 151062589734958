import {
  StyledHeader,
  Toggle,
  Navigation,
  CustomLink,
  Item,
  ItemWrapper,
  Logo,
} from "./Header.Styled";
import { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import logo from "../../../assets/image/logo.png";
import { useNavigate } from "react-router-dom";
import Cookies from "universal-cookie";
import props from "../../../redux/props";
import { setLocal } from "../../../redux/action/local";
import { Container } from "@think-internet/ui-components";
import GetIcon from "../../helper/GetIcon";

const Header = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const token = useSelector((s) => s[props.TOKEN]);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);

  const logout = () => {
    const cookies = new Cookies();
    cookies.remove("token", { path: "/" });
    dispatch(setLocal(props.ACCOUNT, null));
    dispatch(setLocal(props.TOKEN, null));
    navigate("/");
  };

  const goHome = () => {
    if (token) {
      navigate("/dashboard/home");
    } else {
      navigate("/");
    }
  };

  return (
    <StyledHeader>
      <ItemWrapper>
        <Item>
          <Logo onClick={goHome} height={"60px"} src={logo} alt="" />
        </Item>
        <Toggle onClick={() => setOpen(!open)}>
          <GetIcon name="bars" />
        </Toggle>
      </ItemWrapper>
      {open && (
        <Navigation>
          <Container>
            {token && (
              <>
                <CustomLink
                  bold
                  onClick
                  href="/dashboard"
                  text={translation.dashboard.nav.dashboard}
                />
                <CustomLink
                  bold
                  onClick
                  href="/profile"
                  text={translation.dashboard.nav.profile}
                />
                <CustomLink
                  bold
                  onClick={logout}
                  text={translation.dashboard.logoutCTA}
                />
              </>
            )}
            {!token && (
              <>
                <CustomLink
                  bold
                  href="/login"
                  onClick
                  text={translation.dashboard.loginCTA}
                />
                <CustomLink
                  bold
                  href="/signup"
                  onClick
                  text={translation.dashboard.signupCTA}
                />
              </>
            )}
          </Container>
        </Navigation>
      )}
    </StyledHeader>
  );
};

export default Header;
