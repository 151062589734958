import { Route, Routes } from "react-router-dom";
import Layout from "../Layout/Layout";
import Overview from "./Overview/Overview";
import BlogView from "./BlogView/BlogView";
import ArticleView from "./ArticleView/ArticleView";

const Dashboard = () => {
  return (
    <Layout>
      <Routes>
        <Route path={"blog/:blogUUID/:uuid"} element={<ArticleView />} />
        <Route path={"blog/:uuid"} element={<BlogView />} />
        <Route path={"*"} element={<Overview />} />
      </Routes>
    </Layout>
  );
};

export default Dashboard;
