const props = {
  TOKEN: "TOKEN",
  ACCOUNT: "ACCOUNT",
  CURRENTLANG: "CURRENTLANG",
  TRANSLATION: "TRANSLATION",

  TEAM: "TEAM",
  USER: "USER",
  BLOG: "BLOG",
  BLOG_ARTICLE: "BLOG_ARTICLE",
};

export default props;
