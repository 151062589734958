import { Button, Container, DragAndDrop, Input, Textarea } from '@think-internet/ui-components'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom'
import { s3Upload } from '../../../fetch'
import useCRUD from '../../../interface/useCRUD'
import useFunctional from '../../../interface/useFunctional'
import { setLocal } from '../../../redux/action/local'
import props from '../../../redux/props'
import routes from '../../../redux/routes'
import { getLocalURLOfFile, maxFileSizeInBytes } from '../../../utility'
import { Elements, StyledArticleView, UploadLabel, Controls } from './ArticleView.Styled'
import AssetPreview from './AssetPreview/AssetPreview'

const ArticleView = () => {
  const { uuid, blogUUID } = useParams()
  const [data, setData] = useState(null)
  const call = useFunctional(routes.get_blog_article_asset_upload_url)
  const { get, update, remove } = useCRUD(props.BLOG_ARTICLE)
  const translation = useSelector((s) => s[props.TRANSLATION])
  const articles = useSelector((s) => s[props.BLOG_ARTICLE])
  const dispatch = useDispatch()
  const [uploadActive, setUploadActive] = useState(false)
  const [uploadLabel, setUploadLabel] = useState('')
  const navigate = useNavigate()

  useEffect(() => {
    const fetchArticle = async () => {
      const result = await get({ blogUUID, uuid }, translation.blog.article.error.fetch)
      if (result) {
        setData(result)
      }
    }
    if (!data) {
      fetchArticle()
    }
  })

  const setProp = (prop) => (value) => setData({ ...data, [prop]: value })

  const submit = async () => {
    const result = await update(data, translation.blog.article.error.submit, translation.blog.article.success.submit)
    if (result && Array.isArray(articles)) {
      dispatch(
        setLocal(
          props.BLOG_ARTICLE,
          articles.map((a) => (a.uuid === result.uuid ? result : a)),
        ),
      )
    }
  }

  const generateUploadLabel = (progress) => {
    setUploadLabel(`${progress} %`)
  }

  const addToQueue = async (files) => {
    if (files.length === 1) {
      generateUploadLabel(0)
      setUploadActive(true)
      const file = files[0]
      const meta = { name: file.name, type: file.type, size: file.size }
      const result = await call({
        articleUUID: uuid,
        fileMeta: meta,
      })
      if (result) {
        const { key, presignedURL } = result
        const status = await s3Upload(presignedURL, file, (p) => {
          generateUploadLabel(p)
        })
        if (status) {
          setData({ ...data, asset: key, assetURL: getLocalURLOfFile(file), assetType: file.type })
        }
      }
      setUploadActive(false)
    }
  }

  const removeArticle = async () => {
    const result = await remove({ uuid }, translation.blog.article.error.remove)
    if (result) {
      navigate(`/dashboard/blog/${blogUUID}`)
    }
  }

  const getAllowedImageAndVideoExtensions = () => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'bmp', 'svg']
    const videoExtensions = ['mp4', 'webm', 'ogg']
    return [...imageExtensions, ...videoExtensions]
  }

  if (!data) return <></>
  return (
    <StyledArticleView>
      <Container>
        <Elements>
          <Input value={data.headline} onChange={setProp('headline')} placeholder={translation.blog.article.headline} />
          <Input value={data.publicSince} onChange={setProp('publicSince')} placeholder={translation.blog.article.publicSince} type="date" />
          {!uploadActive && (
            <DragAndDrop
              title={translation.blog.article.asset.dragAndDropLabel}
              hint={translation.blog.article.asset.dragAndDropLabelHint}
              onFileCallback={addToQueue}
              allowedFileExtensions={getAllowedImageAndVideoExtensions()}
              maxFileSizeInBytes={maxFileSizeInBytes}
              maxFileSizeExceededToast={translation.blog.article.asset.maxFileSizeExceededToast}
              multiple={false}
            />
          )}
          {uploadActive && <UploadLabel>{uploadLabel}</UploadLabel>}
          <AssetPreview data={data} />
          <Textarea value={data.text} onChange={setProp('text')} placeholder={translation.blog.article.text} rows="20" />
          <Controls>
            <Button text={translation.blog.article.remove} onClick={removeArticle} />
            <Button text={translation.blog.article.submit} onClick={submit} />
          </Controls>
        </Elements>
      </Container>
    </StyledArticleView>
  )
}

export default ArticleView
