import { StyledCreate, ToggleWrapper, Elements } from './Create.Styled'
import { create } from '../../../../../redux/action/crud'
import props from '../../../../../redux/props'
import { Button, Form, Input } from '@think-internet/ui-components'
const { useState } = require('react')
const { useDispatch, useSelector } = require('react-redux')

const Create = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const dispatch = useDispatch()
  const [open, setOpen] = useState(false)
  const [data, setData] = useState({})

  const submit = () => {
    dispatch(create(props.BLOG, data, false))
  }

  const setProp = (prop) => (value) => setData({ ...data, [prop]: value })

  return (
    <StyledCreate>
      <ToggleWrapper>
        <Button text={translation.blog.create.toggle} onClick={() => setOpen(!open)} />
      </ToggleWrapper>
      {open && (
        <Form onSubmit={submit}>
          <Elements>
            <Input value={data.name} onChange={setProp('name')} placeholder={translation.blog.create.name} />
            <Input value={data.domains} onChange={setProp('domains')} placeholder={translation.blog.create.domains} />
            <Button text={translation.blog.create.submit} type="submit" />
          </Elements>
        </Form>
      )}
    </StyledCreate>
  )
}

export default Create
