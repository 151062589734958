import { Container } from "@think-internet/ui-components";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { list } from "../../../redux/action/crud";
import props from "../../../redux/props";
import { StyledBlogView, Headline, Items } from "./BlogView.Styled";
import Article from "./Article/Article";
import Create from "./Create/Create";

const BlogView = () => {
  const { uuid } = useParams();
  const dispatch = useDispatch();
  const articles = useSelector((s) => s[props.BLOG_ARTICLE]);
  const blogs = useSelector((s) => s[props.BLOG]);

  useEffect(() => {
    dispatch(list(props.BLOG_ARTICLE, { blogUUID: uuid }));
    dispatch(list(props.BLOG));
  }, [dispatch, uuid]);

  const getBlog = () => {
    const blog = blogs.find((b) => b.uuid === uuid);
    return blog;
  };

  if (!Array.isArray(blogs) || !Array.isArray(articles)) return <></>;
  return (
    <StyledBlogView>
      <Container>
        <Create blogUUID={uuid} />
        <Headline>{getBlog().name}</Headline>
        <Items>
          {articles.map((a) => (
            <Article key={a.uuid} data={a} />
          ))}
        </Items>
      </Container>
    </StyledBlogView>
  );
};

export default BlogView;
