import { useEffect, useRef } from 'react'
import { StyledAssetPreview, Image, Video } from './AssetPreview.Styled'

const AssetPreview = ({ data }) => {
  const { asset, assetURL, assetType } = data
  const videoRef = useRef()

  const isImageBasedOnURL = () => {
    const imageExtensions = ['jpg', 'jpeg', 'png', 'gif', 'svg']
    const extension = assetURL.split('.').pop()
    return imageExtensions.includes(extension)
  }

  const isVideoBasedOnURL = () => {
    const videoExtensions = ['mp4', 'webm', 'ogg']
    const extension = assetURL.split('.').pop()
    return videoExtensions.includes(extension)
  }

  const getVideoMimeTypeBasedOnURL = () => {
    const extension = assetURL.split('.').pop()
    return `video/${extension}`
  }

  const isMimeType = (expected) => {
    if (!assetType) return false
    return assetType.includes(expected)
  }

  useEffect(() => {
    if (videoRef.current) {
      videoRef.current.load()
    }
  }, [videoRef])

  if (!assetURL) return <></>
  return (
    <StyledAssetPreview>
      {(isImageBasedOnURL() || isMimeType('image')) && <Image src={assetURL} alt={asset} />}
      {(isVideoBasedOnURL() || isMimeType('video')) && (
        <Video ref={videoRef} src={assetURL} controls>
          <source src={assetURL} type={getVideoMimeTypeBasedOnURL()} />
        </Video>
      )}
    </StyledAssetPreview>
  )
}

export default AssetPreview
