import styled from "styled-components";

export const StyledList = styled.div``;
export const Headline = styled.div`
  font-family: "bold";
`;

export const Items = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
  margin-top: 5px;
`;
