import { Button, Form, Input } from "@think-internet/ui-components";
import {
  StyledPersonalData,
  Split,
  InputWrapper,
  InputSplit,
  Headline,
  SubmitWrapper,
} from "./PersonalData.Styled";
import props from "../../../redux/props";
import { get } from "../../../redux/action/crud";
import { setLocal } from "../../../redux/action/local";
import { useDispatch, useSelector } from "react-redux";
import { useEffect } from "react";
import useCRUD from "../../../interface/useCRUD";

const PersonalData = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const dispatch = useDispatch();
  const account = useSelector((s) => s[props.ACCOUNT]);
  const user = useSelector((s) => s[props.USER]);
  const { update } = useCRUD(props.USER);

  useEffect(() => {
    if (!user || user.uuid !== account.assignedToUUID) {
      dispatch(get(props.USER, { uuid: account.assignedToUUID }));
    }
  });

  const submit = async () => {
    await update(
      user,
      translation.profile.personalData.error.submit,
      translation.profile.personalData.success.submit
    );
  };

  const setProp = (prop) => (value) =>
    dispatch(setLocal(props.USER, { ...user, [prop]: value }, true));

  if (!user) return <></>;
  return (
    <StyledPersonalData>
      <Headline>{translation.profile.personalData.headline}</Headline>
      <Form onSubmit={submit}>
        <Split>
          <InputWrapper>
            <InputSplit>
              <Input
                value={user["firstname"]}
                onChange={setProp("firstname")}
                placeholder={translation.generic.firstname}
              />
              <Input
                value={user["lastname"]}
                onChange={setProp("lastname")}
                placeholder={translation.generic.lastname}
              />
            </InputSplit>
            <Input
              value={user["mail"]}
              onChange={setProp("mail")}
              placeholder={translation.generic.mail}
            />
            <Input
              value={user["password"]}
              onChange={setProp("password")}
              type="password"
              placeholder={translation.generic.password}
            />
          </InputWrapper>
        </Split>
        <SubmitWrapper>
          <Button type="submit" text={translation.generic.save} />
        </SubmitWrapper>
      </Form>
    </StyledPersonalData>
  );
};

export default PersonalData;
