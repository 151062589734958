import { StyledArticle, Name } from "./Article.Styled";

const Article = ({ data }) => {
  return (
    <StyledArticle>
      <Name href={`/dashboard/blog/${data.blogUUID}/${data.uuid}`}>
        {data.headline}
      </Name>
    </StyledArticle>
  );
};

export default Article;
