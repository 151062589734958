import styled from "styled-components";

export const StyledCreate = styled.div``;

export const ToggleWrapper = styled.div`
  display: flex;
  justify-content: right;
`;

export const Elements = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  justify-content: right;
  margin-top: 15px;
`;
