import styled from 'styled-components'

export const StyledAssetPreview = styled.div`
  width: 100%;
`

export const Image = styled.img`
  max-width: 100%;
  max-height: 300px;
`

export const Video = styled.video`
  max-width: 100%;
  max-height: 300px;
`
