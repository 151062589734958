import styled from 'styled-components'

export const StyledActivate = styled.div`
  display: flex;
  flex-wrap: wrap;
  padding: 50px 0px;
  gap: 15px;
  justify-content: center;
`

export const Message = styled.div`
  width: 100%;
  text-align: center;
  font-size: ${({ theme }) => theme.font.size.lg};
`
