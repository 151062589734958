import { StyledLayout, CoreContent } from './Layout.Styled'
import Header from './Header/Header'
import Footer from './Footer/Footer'
import Secure from './Secure/Secure'

const Layout = ({ children, className, hideHeader = false, authRequired = true }) => {
  return (
    <StyledLayout className={className}>
      <Secure authRequired={authRequired}>
        {!hideHeader && <Header />}
        <CoreContent hideHeader={hideHeader}>{children}</CoreContent>
        <Footer />
      </Secure>
    </StyledLayout>
  )
}

export default Layout
