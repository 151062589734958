import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { list } from "../../../../../redux/action/crud";
import props from "../../../../../redux/props";
import { StyledList, Headline, Items } from "./List.Styled";
import Blog from "./Blog/Blog";

const List = () => {
  const translation = useSelector((s) => s[props.TRANSLATION]);
  const blogs = useSelector((s) => s[props.BLOG]);
  const dispatch = useDispatch();

  useEffect(() => {
    if (!blogs) {
      dispatch(list(props.BLOG));
    }
  });

  return (
    <StyledList>
      <Headline>{translation.blog.list.headline}</Headline>
      {Array.isArray(blogs) && (
        <Items>
          {blogs.map((b) => (
            <Blog key={b.uuid} data={b} />
          ))}
        </Items>
      )}
    </StyledList>
  );
};

export default List;
