import { StyledSignup, HeroForm, CustomHeadline, Flex } from './Signup.Styled'
import { Form, Container, Button, Input } from '@think-internet/ui-components'
import Layout from '../Layout/Layout'
import { useSelector } from 'react-redux'
import { useState } from 'react'
import props from '../../redux/props'
import useFunctional from '../../interface/useFunctional'
import routes from '../../redux/routes'

const Signup = () => {
  const translation = useSelector((s) => s[props.TRANSLATION])
  const [data, setData] = useState({})
  const signup = useFunctional(routes.signup)

  const submit = async () => {
    const result = await signup(data, translation.signup.error, translation.signup.success)
    if (result) {
      setData({})
    }
  }

  const setProp = (prop) => (value) => setData({ ...data, [prop]: value })

  return (
    <Layout authRequired={false}>
      <StyledSignup>
        <Container>
          <CustomHeadline text={translation.signup.headline} />
          <HeroForm>
            <Form onSubmit={submit}>
              <Flex gap="15px">
                <Input placeholder={translation.generic.firstname} onChange={setProp('firstname')} value={data.firstname} required={true} />
                <Input placeholder={translation.generic.lastname} onChange={setProp('lastname')} value={data.lastname} required={true} />
                <Input type="email" placeholder={translation.generic.mail} onChange={setProp('identifier')} value={data.identifier} required={true} />
                <Input
                  type="password"
                  placeholder={translation.generic.password}
                  onChange={setProp('password')}
                  value={data.password}
                  required={true}
                />
                <Button type="submit" text={translation.signup.cta} />
              </Flex>
            </Form>
          </HeroForm>
        </Container>
      </StyledSignup>
    </Layout>
  )
}

export default Signup
